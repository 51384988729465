<template>
    <div class="px-4 text-14 pb-5">
        <form class="p-fluid pt-2" @submit.prevent="">
            <div class="field mb-4">
                <label class="text-black text-14 roboto-medium">
                    SoF *
                </label>
                <Dropdown
                    v-model="form.selected_sof"
                    :options="sofs"
                    optionLabel="name"
                    placeholder="Pilih Source of Fund"
                    :disabled="form.is_submit"
                />
            </div>
            <div class="field mb-4">
                <label class="text-black text-14 roboto-medium">
                    Minimum Amount *
                </label>
                <InputNumber
                    v-model="form.min_amount"
                    mode="currency"
                    currency="IDR"
                    locale="id-ID"
                    :minFractionDigits="0"
                    :min="0"
                />
            </div>
            <div class="field mb-4">
                <label class="text-black text-14 roboto-medium">
                    Fee *
                </label>
                <InputNumber
                    v-model="form.fee"
                    mode="currency"
                    currency="IDR"
                    locale="id-ID"
                    :minFractionDigits="0"
                    :min="0"
                />
            </div>
            <div class="field mb-4">
                <label class="text-black text-14 roboto-medium">
                    Type *
                </label>
                <Dropdown
                    v-model="form.selected_type"
                    :options="types"
                    optionLabel="value"
                    placeholder="Pilih Tipe"
                    :disabled="form.is_submit"
                />
            </div>
            <div class="field mb-4">
                <label class="text-black text-14 roboto-medium">
                    Transfer ke *
                </label>
                <Dropdown
                    v-model="form.selected_transfer_to"
                    :options="transfers"
                    optionLabel="value"
                    placeholder="Pilih Transfer ke"
                    :disabled="form.is_submit"
                />
            </div>
            <div class="field mb-4" v-if="form.selected_type.value && form.selected_type.value === 'OUTBOUND'">
                <label class="text-black text-14 roboto-medium">
                    Negara *
                </label>
                <Dropdown
                    v-model="form.selected_country"
                    :options="countries"
                    optionLabel="name"
                    placeholder="Pilih Negara"
                    :disabled="form.is_submit"
                />
            </div>
        </form>
    </div>
</template>

<script>
export default {
    props: ['data', 'sofs', 'countries'],
    components: {},
    data() {
        return {
            form: {
                id: null,
                min_amount: 0,
                fee: 0,
                selected_sof: '',
                sof_id: '',
                selected_type: '',
                type: '',
                selected_transfer_to: '',
                transfer_to: '',
                selected_country: '',
                country: '',
                is_submit: false,
            },
            types: [
                {value: 'MYNT'},
                {value: 'INBOUND'},
                {value: 'OUTBOUND'},
            ],
            transfers: [
                {value: 'ACCOUNT'},
                {value: 'CASH'},
            ],
        }
    },
    watch: {
        'form': {
            handler: function() {
                this.form.sof_id = this.form.selected_sof ? this.form.selected_sof['id'] : ''
                this.form.type = this.form.selected_type ? this.form.selected_type['value'] : ''
                this.form.transfer_to = this.form.selected_transfer_to ? this.form.selected_transfer_to['value'] : ''
                this.form.country = this.form.type === 'INBOUND' || !this.form.selected_country
                    ? 'INDONESIA'
                    : this.form.selected_country['name'].toUpperCase()
                this.form.sof_name = this.form.selected_sof ? this.form.selected_sof['name'] : ''
                this.$emit('updated', this.form)
            },
            deep: true,
        },
    },
    mounted() {
        if (Object.keys(this.data).length === 0) return

        this.form = {...this.form, ...this.data}
        this.form.selected_sof = this.sofs.find(obj => obj.id == this.form.sof_id);
        this.form.selected_type = this.types.find(obj => obj.value == this.form.type);
        this.form.selected_transfer_to = this.transfers.find(obj => obj.value == this.form.transfer_to);
        this.form.selected_country = this.countries.find(obj => obj.name.toLowerCase() == this.form.country.toLowerCase());
    },
    methods: {},
}
</script>

<style lang="scss" scoped></style>
